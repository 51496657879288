<template>
  <div class="green-section--mob">
    <GreenBlock>
      <v-container>
        <h1 class="page-title">Willkommen zur Zählerstandserfassung</h1>
        <div class="green-section__content">
          <div class="green-section--left">
            <div class="eprimo-smile-img">
              <img src="~@/assets/images/eprimo-face-2.svg" alt="eprimo-face">
            </div>
            <div class="page-subtitle">Für eine korrekte Berechnung zur Nutzung Ihres Strompaketes benötigen wir in
              regelmäßigen Abständen aktuelle Zählerstände. Diese können Sie leicht und komfortabel über dieses System an uns melden.
            </div>
          </div>
        </div>
      </v-container>
    </GreenBlock>
    <v-container>
      <div class="box-shadow mb-0">
        <h3 class="section__title">Bitte geben Sie den Code ein, den Sie per E-Mail erhalten haben</h3>
        <p>Auf der nächsten Seite können Sie dann die Zählerstandsmeldung durchführen.</p>
        <v-form
            ref="form"
            @submit.prevent="login"
            class="form-login"
        >
          <div class="form-input-block">
            <div class="form-placeholder">Ihr E-Mail Code</div>
            <input
                class="form-input" :class="{'form-input--error': error || errorProp}"
                v-model="token"
                type="text"
                placeholder="E-Mail Code"
                required
            >
            <p v-if="error || errorProp" class="form-error">
              {{ errorMessage }}
            </p>
          </div>
          <div class="text-center-mobile">
            <button
                class="btn"
                type="submit"
            >
              Weiter zur Zählerstandserfassung
            </button>
          </div>
        </v-form>
      </div>
    </v-container>
  </div>
</template>

<script>

import GreenBlock from "@/components/GreenBlock";

export default {
  name: 'LoginPage',
  components: {
    GreenBlock,
  },
  props: ['errorProp', 'uuidProp'],
  data() {
    return {
      token: null,
      error: false,
      errorMessage: 'Ungültiger E-Mail Code. Bitte verwenden Sie den richtigen Code aus der letzten E-Mail, die wir Ihnen dazu gesendet haben.'
    }
  },
  methods: {
    login() {
      fetch(process.env.VUE_APP_BACKEND_BASE_URL + 'meter_reading/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          token: this.token
        })
      })
      .then(res => res.json())
      .then(data => {
        if (data.success) {
          this.$router.push({ name: 'input', params: { token: this.token, loggedIn: true, data: data.data } });
        } else {
          this.error = true;
        }
      })
    }
  },
  mounted() {
    if (this.uuidProp) {
      this.token = this.uuidProp;
    }
  }
}
</script>

<style lang="scss" scoped>
.green-section {
  padding: 15px 0 40px;
}

.green-section--right {
  margin: -40px 37px -40px 0;
}

input::placeholder {
  color: rgba(110, 110, 110, 0.4);
  font-size: 16px;
  font-weight: 600;
}

.green-section__content .page-subtitle {
  font-size: 18px;
  font-weight: 600;
}

.form-error {
  margin-top: 10px;
}

.form {
  &-input {
    border-radius: 4px;
    height: 40px;
    width: 100%;
    display: block;
    border: 1px solid $green-3;
    padding: 6px 12px;

    &:focus {
      box-shadow: inset 1px 1px rgba(0, 0, 0, .075), 0 0 5px rgba(180, 218, 4, .5);
    }

    &-block {
      width: 400px;
      margin-right: 30px;
    }

    &--error {
      border-color: $orange;
    }
  }

  &-error {
    margin-bottom: 0;
    color: $orange;
  }

  &-login {
    .btn {
      margin-top: 20px;
    }
  }

  &-placeholder {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 8px;
  }
}

.eprimo-smile-img {
  float: right;
  margin: -40px 32px 0 0;
}

@media (max-width: 768px) {

  .section__title {
    margin-bottom: 5px;
  }

  .form-input-block {
    width: 100%;
  }

  .page-title {
    font-size: 20px;
  }

  .green-section__content .page-subtitle {
    font-size: 14px;
    font-weight: 400;
  }

  .green-section .list-checkmark {
    font-size: 14px;

    li {
      margin-bottom: 9px;
    }
  }

  .green-section--mob {
    padding: 10px 0 20px;
    background-color: $green-1;
    background-image: -webkit-gradient(linear, left top, left bottom, from($green), to(rgba(255, 255, 255, 0)));
    background-image: -o-linear-gradient(top, $green, rgba(255, 255, 255, 0));
    background-image: linear-gradient(180deg, $green, rgba(255, 255, 255, 0));
    background-size: cover;
    background-repeat: repeat-x;
    position: relative;
    margin-bottom: 10px;

    &:before {
      content: '';
      background-image: url('~@/assets/images/bg-curve-1.png');
      height: 3vw;
      position: absolute;
      bottom: -.0625rem;
      display: block;
      width: 100%;
      background-position: top;
      background-size: cover;
      z-index: 0;
    }
  }

  .green-section--mob .green-section {
    background-image: none;
    background-color: transparent;
    margin-bottom: 10px;

    &:before {
      display: none;
    }
  }

  .eprimo-smile-img {
    margin: -20px -20px 0 0;

    img {
      width: 140px;
    }
  }

  .btn:not(.popover-email) {
    font-size: 14px;
    width: 100%;
  }

}

@media (min-width: 768px) {
  .form {
    &-login {
      //max-width: 800px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      width: 100%;
      margin: 0 auto 20px;
    }

    &-login {
      .btn {
        margin-top: 36px;
        white-space: nowrap;
        margin-right: 15px;
      }
    }
  }
}
</style>
